import { render, staticRenderFns } from "./ButtonArrow.vue?vue&type=template&id=12637dee&scoped=true&"
import script from "./ButtonArrow.vue?vue&type=script&lang=js&"
export * from "./ButtonArrow.vue?vue&type=script&lang=js&"
import style0 from "./ButtonArrow.vue?vue&type=style&index=0&id=12637dee&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12637dee",
  null
  
)

export default component.exports